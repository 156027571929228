<template>
  <main>
    <WebAppsSection
      v-for="(section, index) in sections"
      :key="index"
      :section="section"
    />
  </main>
</template>

<script setup>
import WebAppsSection from "@/components/Badges/WebAppsSection.vue";

const sections = [
  {
    color: "rgba(4, 231, 245, 1)",
    logo: "ypa_logo.png",
    type: "desktop",
    items: [
      {
        header: "Wyszukiwanie miejsc parkingowych",
        text: "Użytkownicy mogą szybko znaleźć dostępne miejsca parkingowe w swojej okolicy, korzystając z intuicyjnej wyszukiwarki oraz filtrów umożliwiających dopasowanie wyników do ich potrzeb.",
      },
      {
        header: "Rezerwacja i płatności online",
        text: "Aplikacja umożliwia rezerwację miejsc parkingowych oraz dokonywanie bezpiecznych płatności online, co zapewnia wygodę i oszczędność czasu użytkowników.",
      },
      {
        header: "Zarządzanie miejscami parkingowymi",
        text: "Właściciele miejsc mogą zarządzać swoimi ofertami, ustalać ceny, dostępność oraz monitorować rezerwacje, co pozwala im na pełną kontrolę nad wynajmem.",
      },
      {
        header: "Powiadomienia i przypomnienia",
        text: "Użytkownicy otrzymują powiadomienia o nadchodzących rezerwacjach, płatnościach oraz ważnych informacjach dotyczących wynajmu, co pomaga im zarządzać czasem i uniknąć niepotrzebnych opłat.",
      },
      {
        header: "Oceny i opinie",
        text: "System ocen i opinii pozwala użytkownikom na dzielenie się doświadczeniami z wynajmu, co zwiększa transparentność i ułatwia wybór odpowiednich miejsc parkingowych.",
      },
      {
        header: "Bezpieczeństwo i wsparcie",
        text: "Aplikacja zapewnia bezpieczne transakcje oraz oferuje wsparcie techniczne dla użytkowników, co gwarantuje bezproblemowe korzystanie z platformy.",
      },
    ],
    mockups: ["mockups/Mockup4-YPA.png"],
  },
  {
    color: "rgba(42, 87, 151, 1)",
    logo: "hotelHubLogo.png",
    type: "mobile",
    items: [
      {
        header: "Nawigacja po hotelu",
        text: "Użytkownicy mogą korzystać z aplikacji, aby szybko odnaleźć interesujące ich miejsca w hotelu, jak i poza nim takie jak recepcja, restauracje, baseny, centra fitness, spa itp.",
      },
      {
        header: "Zasady i regulacje",
        text: "Aplikacja zawiera szczegółowe opisy zasad panujących w hotelu, takie jak procedury wymeldowania, zasady dotyczące korzystania z basenów czy siłowni, co pozwala uniknąć nieporozumień i zapewnia komfortowy pobyt.",
      },
      {
        header: "Elastyczność",
        text: "HotelHub został zaprojektowany z myślą o łatwej modyfikacji, co pozwala dostosować go do konkretnych potrzeb i wymagań każdego hotelu. W ten sposób aplikacja może być zoptymalizowana pod kątem specyficznych usług i udogodnień oferowanych przez dany obiekt.",
      },
      {
        header: "Integracja z systemami hotelowymi",
        text: "Dzięki możliwości integracji z systemami hotelowymi, HotelHub może efektywnie współpracować z istniejącymi narzędziami zarządzania obiektem, co ułatwia obsługę gości i optymalizuje procesy operacyjne.",
      },
    ],
    mockups: ["mockups/Mockup-HH.svg"],
  },
  {
    color: "#0D6EFD",
    logo: "minerHostLogo.png",
    type: "desktop",
    items: [
      {
        header: "Panel Klienta",
        text: "Klienci mogą monitorować i zarządzać swoimi koparkami w wygodny sposób. Przejrzyste śledzenie rozliczeń oraz ewentualnych zaległości płatniczych. Możliwość kontrolowania pracy koparek poprzez intuicyjny interfejs.",
      },
      {
        header: "Panel Administracyjny",
        text: "Admin ma kontrolę nad koparkami klientów, ich lokalizacjami oraz typami. Automatyczne generowanie rozliczeń dla klientów, co przyspiesza proces finansowy. Wysyłanie masowych rozliczeń przez e-mail dla efektywnej komunikacji z klientami.",
      },
      {
        header: "Statystyki i Analizy",
        text: "Zapewnienie bogatej analizy danych finansowych, w tym przychodów, dochodów netto itp. Możliwość śledzenia kluczowych wskaźników wydajności i dochodów. Tworzenie raportów dla podejmowania strategicznych decyzji biznesowych.",
      },
    ],
    mockups: ["mockups/Mockup-MH.svg"],
  },
  {
    color: "#0D6EFD",
    logo: "obmLogo.png",
    type: "desktop",
    items: [
      {
        header: "Precyzyjne Obliczenia",
        text: "Wykorzystanie danych z giełdy WhatToMine gwarantuje dokładne obliczenia rentowności koparek. Automatyczne aktualizacje danych z giełdy, zapewniające użytkownikom najświeższe informacje.",
      },
      {
        header: "Personalizacja Koparek",
        text: "Użytkownicy mogą dodawać swoje koparki do aplikacji, aby obliczyć ich rentowność. Możliwość monitorowania rentowności koparek na bieżąco w jednym miejscu.",
      },
      {
        header: "Pełna Transparentność",
        text: "Użytkownicy mogą sprawdzić, jakie dane są używane do obliczeń rentowności, co zapewnia pełną przejrzystość procesu.",
      },
    ],
    mockups: ["mockups/Mockup-KALK.svg"],
  },
  {
    color: "#464646",
    logo: "exsituLogo.png",
    type: "mobile",
    items: [
      {
        header: "Monitoring Na Żywo",
        text: "Użytkownicy mają dostęp do bieżących zdarzeń systemowych w czasie rzeczywistym, co umożliwia szybką reakcję na sytuacje awaryjne.",
      },
      {
        header: "Interaktywny Interfejs",
        text: "Intuicyjny interfejs użytkownika umożliwia łatwe przeglądanie zdarzeń i podjęcie odpowiednich działań w odpowiedzi na sytuacje krytyczne.",
      },
      {
        header: "Pełna Kompatybilność",
        text: "Aplikacja jest w pełni zintegrowana z istniejącym systemem bezpieczeństwa, umożliwiając wymianę danych i sterowanie z poziomu urządzeń mobilnych.",
      },
      {
        header: "Natychmiastowe Alerty",
        text: "Użytkownicy otrzymują natychmiastowe powiadomienia push w przypadku zdarzeń alarmowych lub sytuacji wymagających ich uwagi.",
      },
    ],
    mockups: ["mockups/Mockup-EX.svg"],
  },
];
</script>

<style scoped></style>
